import { Text } from "@clipboard-health/ui-react";
import { Box, Divider, Stack } from "@mui/material";
import {
  ScoreAdjustmentRow,
  type ScoreAdjustmentRowProps,
} from "@src/appV2/AttendanceScore/components/ScoreAdjustmentRow";
import { useState } from "react";

export interface ScoreActivityProps {
  isClipboardScoreEnabled: boolean;
  activity: Array<Omit<ScoreAdjustmentRowProps, "handleChange" | "expanded">>;
}

export function ScoreActivity(props: ScoreActivityProps) {
  const [expandedRow, setExpandedRow] = useState<string | undefined>(undefined);

  const handleChange = (id: string, isExpanded: boolean) => {
    setExpandedRow(isExpanded ? id : undefined);
  };

  const { isClipboardScoreEnabled, activity } = props;
  return (
    <Box>
      <Stack
        direction="row-reverse"
        flexGrow={1}
        paddingLeft={1}
        paddingRight={2}
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          divider={<Divider flexItem orientation="vertical" variant="middle" />}
          direction="row"
          justifyContent="space-between"
          spacing={1}
          paddingRight={1}
        >
          <Text italic>Change</Text>
          <Text
            italic
            textAlign="end"
            sx={{
              minWidth: "2rem",
            }}
          >
            Score
          </Text>
        </Stack>
      </Stack>{" "}
      {activity.map((adjustment) => (
        <ScoreAdjustmentRow
          key={adjustment.id}
          {...adjustment}
          expanded={expandedRow === adjustment.id}
          handleChange={(id, isExpanded) => {
            handleChange(id, isExpanded);
          }}
        />
      ))}
      <Text italic paddingTop={2}>
        {isClipboardScoreEnabled
          ? "Only showing the last 20 adjustments to your account since the launch of Clipboard Score."
          : "Only the last 20 adjustments to your account are shown above."}
      </Text>
    </Box>
  );
}
