import { BottomSheet, PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { Text } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent, Stack, ThemeProvider } from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useWorkerPreferences/types";
import { type AgentLicenseData } from "@src/appV2/Agents/types";
import { OpenShiftLicenseField } from "@src/appV2/OpenShifts/components/OpenShiftCalendar/Dialogs/LicenseField";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { IconButton } from "@src/appV2/redesign/components/IconButton";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface QualificationPreferencesBottomSheetProps {
  modalState: UseModalState;
  agentLicenses: AgentLicenseData[];
  onSubmit: (values: AgentPreferences) => Promise<void>;
  defaultValues: AgentPreferences;
}

const qualificationPreferencesSchema = z.object({
  license: z.string(),
  distance: z.number(),
});

export function QualificationPreferencesBottomSheet(
  props: QualificationPreferencesBottomSheetProps
) {
  const { modalState, agentLicenses, onSubmit, defaultValues } = props;

  const formMethods = useForm<AgentPreferences>({
    defaultValues,
    resolver: zodResolver(qualificationPreferencesSchema),
  });
  const { control, handleSubmit, formState } = formMethods;

  const sortedUniqueAgentLicenses = agentLicenses
    .filter(
      (value, index, self) =>
        self.findIndex((license) => license.qualification === value.qualification) === index
    )
    .sort((licenseA, licenseB) => licenseA.qualification.localeCompare(licenseB.qualification));

  return (
    <ThemeProvider theme={getTheme()}>
      <BottomSheet
        modalState={modalState}
        footer={
          <DialogFooter orientation="vertical">
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <IconButton
                size="large"
                variant="outlined"
                iconType="close"
                onClick={modalState.closeModal}
              />
              <LoadingButton
                aria-label={formState.isSubmitting ? "Saving Preferences" : "Save Preferences"}
                isLoading={formState.isSubmitting}
                variant="contained"
                size="large"
                onClick={handleSubmit(async (formData) => {
                  await onSubmit(formData);
                  modalState.closeModal();
                })}
              >
                Update
              </LoadingButton>
            </Stack>
          </DialogFooter>
        }
      >
        <DialogContent>
          <Stack spacing={8}>
            <PageHeaderWrapper variant="tertiary">
              <Title variant="h4" component="h4">
                Qualification Preference
              </Title>
            </PageHeaderWrapper>

            <Stack spacing={4}>
              <Text variant="body1">See your ranking for a different qualification.</Text>
              {agentLicenses.length > 0 && (
                <OpenShiftLicenseField
                  name="license"
                  control={control}
                  agentLicenses={sortedUniqueAgentLicenses}
                />
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </BottomSheet>
    </ThemeProvider>
  );
}
